<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <router-link
        class="bg-green-1000 text-white px-6 py-3 rounded-md focus:outline-none"
        :to="{ name: 'list-vacancies' }"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        Voltar
      </router-link>
          
      <div class="p-4 flex mb-3 mt-3">
        <h1 class="text-3xl">Candidatos Inscritos: {{ vacancyTitle }}</h1>
      </div>

      <!-- steps -->
      <div class="w-full py-6 mb-3">
        <div class="flex">
          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <!-- <h1 class="text-white">1°</h1> -->
                  <i class="ri-file-list-line"></i>
                </span>
              </div>
            </div>
            <div class="text-xs text-center md:text-base">Em inscrição</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div v-if="vacancyStatus != 'Em seleção'" class="w-0 bg-green-500 py-1 rounded" style="width: 60%;"></div>
                  <div v-else class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div v-if="vacancyStatus != 'Em seleção'" class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <svg  class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" fill="rgba(75,85,99,1)"/>
                  </svg>          
                </span>
              </div>
              <div v-else class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" fill="rgba(255,255,255,1)"/>
                  </svg>       
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Em Seleção</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div v-if="vacancyStatus != 'Em seleção'" class="w-0 bg-green-500 py-1 rounded" style="width: 0%;"></div>
                  <div v-else class="w-0 bg-green-500 py-1 rounded" style="width: 30%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(75,85,99,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Agendar entrevista</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 0%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(75,85,99,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Candidatos aprovados</div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="flex mb-4 justify-end" v-if="vacancyExternal != 1">

          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded-md focus:outline-none"
            :to="{ name: 'applying-participant' }"
          >
          <i class="ri-user-add-line mr-2"></i>
            Adicionar Participante
          </router-link>
        </div>
        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              class="mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :to="{ name: 'curriculum', params: { id: row.id } }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i class="ri-article-line"></i>
            </router-link>
          </div>
          <div
            class="flex justify-center items-center"
            slot="actions"
            slot-scope="{ row }"
          >
            <button
              class="mr-3 text-sm border-2 border-red-500 hover:bg-red-500 hover:text-white px-6 py-3 rounded focus:outline-none focus:shadow-outline"
              @click="deleteParticipant(row)"
              v-tooltip="{ content: 'Excluir Participante', placement: 'left' }"
              v-if="
                row.status !== 'Inscrito(a)' && row.status !== 'Interessado(a)'
              "
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </div>
        </v-client-table>

        <v-client-table
          v-if="participantsExternal != ''"
          ref="table"
          :data="participantsExternal"
          :columns="columnsExternals"
          :options="options"
        >
          <div class="flex justify-center items-center" slot="status">
            Participante Externo
          </div>
        </v-client-table>

        <div class="flex mb-4 justify-end">
          <router-link
            v-if="vacancyExternal != 1"
            v-show="vacancyStatus === 'Em seleção'"
            class="bg-green-1000 justify-end text-white px-6 py-3 rounded-md focus:outline-none"
            :to="{
              name: 'select-participant',
              params: { id: this.$route.params.id }
            }"
          >
          <i class="ri-check-fill mr-1"></i>
            Selecionar Candidatos
          </router-link>
          <button
            v-show="vacancyStatus != 'Finalizado'"
            v-else
            @click="emptyInscription"
            class="bg-green-1000 justify-end text-white px-6 py-3 rounded-md focus:outline-none"
          >
            Finalizar Vaga
          </button>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import axios from '@/utils/axios';
import DashboardLayout from '../../../layouts/DashboardLayout';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'SubscribleParticipants',

  title() {
    return `${process.env.VUE_APP_NAME} | Participantes Inscritos`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      vacancyTitle: '',
      vacancyStatus: '',
      vacancyExternal: null,
      vacancyAvaliable: null,
      participants: [],
      participantsExternal: [],
      columns: ['id', 'name', 'status', 'resume', 'actions'],
      columnsExternals: ['name', 'status'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          document: 'E-mail',
          resume: 'Currículo',
          actions: 'Ações',
          status: 'Status'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    fetchParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/participante/${this.$route.params.id}`)
        .then(({ data }) => {
          data.participants.forEach(participant => {
            if (participant.status === 'Interessado(a)')
              this.$router.push({
                name: 'finalizing-vacancy',
                params: { id: this.$route.params.id }
              });

            this.participants.push(participant);
          });

          data.participantsExternals.forEach(participantsExternal => {
            this.participantsExternal.push(participantsExternal);
          });
          this.isLoading = false;
        });
    },

    fetchVacancy() {
      axios
        .get(`/api/vagas/${this.$route.params.id}/vaga/`)
        .then(({ data }) => {
          this.vacancyTitle = data.data[0].office;
          this.vacancyExternal = data.data[0].link_extern_option;
          this.vacancyAvaliable = data.data[0].vacancies_available;
          this.vacancyStatus = data.data[0].status;
        });
    },

    emptyInscription() {
      if (this.participants.length == 0) {
        this.$confirm({
          message: 'Realmente nenhum contratado foi da colheita?',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              const participantsExternal = {
                participants: [],
                vacancy: []
              };
              for (let index = 0; index < this.vacancyAvaliable; index++) {
                participantsExternal.participants[index] = prompt(
                  'Nome do Participante'
                );
              }
              participantsExternal.vacancy.push(this.$route.params.id);

              axios
                .post(
                  '/api/participante/participante-externo',
                  participantsExternal
                )
                .then(({ data }) => {
                  this.$toast.success(data.data.message);

                  axios
                    .put(`/api/vagas/status/${this.$route.params.id}`, {
                      status: 4
                    })
                    .then(() => {
                      this.$toast.success('Oportunidade plantada com sucesso!');
                      setTimeout(() => {
                        this.$router.push({ name: 'list-vacancies' });
                      }, 2000);
                    });
                });
            }
          }
        });
      } else {
        this.finishVacancy();
      }
    },

    deleteParticipant(row) {
      const deleteParticipant = {
        participant: row.id,
        vacancy: this.$route.params.id
      };

      this.$confirm({
        message: 'Tem certeza que deseja excluir este participante?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .put('api/vagas/excluir-participante', deleteParticipant)
              .then(({ data }) => {
                this.$toast.success(data.data.message);
                setTimeout(() => {
                  this.$router.go(0);
                }, 1500);
              });
          }
        }
      });
    },

    finishVacancy() {
      axios.put(`/api/vagas/status/${this.$route.params.id}`, {
        status: 4
      });
      this.$toast.success('Oportunidade Finalizada com Sucesso!');
      setTimeout(() => {
        this.$router.go(0);
      }, 2000);
    }
  },

  created: function() {
    this.fetchParticipants();
    this.fetchVacancy();
  }
};
</script>

<style scoped></style>
